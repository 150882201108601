import React, { Component } from "react";
import Select, { StylesConfig,components } from "react-select";
import styleVariables from '@/styles/variables.module.scss';
import styles from "./rdg-dropdown-styles.module.scss";

export const mapOptionsFromString = (x) => ({value: x, label: x});


const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props} className={styles["rdgdropdown-toggle"]}></components.DropdownIndicator>
    );
  };

export class  RDGDropdown extends Component< {
    id: string,
    name: string,
    options: {value: string, label: string}[],
    menuPortalTarget: any,
    isMulti: boolean,
    value ?: any,
    styles ?: StylesConfig<any>,
    placeholder ?: string,
    usePrimaryColor ?: boolean,
    onChange: (e) => any,
    onBlur: (e) => any,
    clearValue: (e) => any,
    dataCy ?: string;
}, {}> {
    selectRef: React.RefObject<any>;
    defaultStyles: StylesConfig;

    constructor(props) {
        super(props);
        this.selectRef = React.createRef<any>();
        this.defaultStyles = {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#F2F2F2' : provided.backgroundColor,                
            }),
            multiValue: (styles) => ({
                ...styles,
                borderRadius: '30px',
                paddingLeft: '5px'
            }),
            menu: ({width, ...styles}) => ({
                ...styles,
                marginTop: 0,
                width: "max-content",
                minWidth: "100%"
            }),
            control: (styles, state) => ({
                ...styles,
                backgroundColor: props.usePrimaryColor ? styleVariables.primaryColor : styles.backgroundColor,
                color: props.usePrimaryColor ? 'white' : styles.color,
                width: 135,
                border: '1px solid #4f56a5',
                borderRadius: '8px',
                fontWeight:400,
                padding: '0.3rem 0.3rem 0.3rem 0.3rem'
            }),
            placeholder: (styles) => ({
                ...styles,
                color: props.usePrimaryColor ? 'white' : styles.color
            }),
            indicatorSeparator: (styles) => ({
                ...styles,
                display: 'none'
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                color: props.usePrimaryColor ? 'white' : styles.color,
                '& svg': { display: 'none' }
            }),
            valueContainer: (styles) => ({
                ...styles,
                padding:'2px 5px'
            })
        }
    }
    clearValue() {
        this.selectRef.current.clearValue();
    }

    render() {
        return (
            <Select
                id={this.props.name}
                data-cy={this.props.name}
                classNamePrefix={this.props.name}
                {...this.props}
                ref={this.selectRef}
                styles={this.props.styles || this.defaultStyles}
                isSearchable={false}
                components={{ DropdownIndicator }}
                value='Actions'
            />
        )
    }
}