import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import RDGButton from '@/components/common/button/RDGButton';
import styles from './rdgModal-styles.module.scss';

const RdgModal = (props) => {
    const { showModal, modalTitle, modalBody,
        showHeader, showHeaderCloseButton,
        showYesButton, yesButtonLabel = "Yes", modalYesButtonClickHandle,
        showNoButton, noButtonLabel = "No",modalNoButtonClickHandle,
        showOkButton, okButtonLabel = "Ok", modalOkButtonClickHandle
    } = props;
    const [show, setShow] = useState(showModal);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Modal 
            show={show} onHide={handleClose}
            backdrop="static" centered 
            data-cy="rdg-modal">
            {
                modalTitle && 
                <Modal.Header>
                    <Modal.Title><h6>{modalTitle}</h6></Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
               <h6>{modalBody}</h6>
            </Modal.Body>
            <Modal.Footer>
                {showNoButton && <RDGButton isSecondary isOutline sm dataCy="modal-no-button" onClick = {modalNoButtonClickHandle}> {noButtonLabel} </RDGButton>}
                {showYesButton && <RDGButton isPrimary sm dataCy="modal-yes-button" onClick = {modalYesButtonClickHandle}> {yesButtonLabel} </RDGButton>}
                {showOkButton && <RDGButton isPrimary sm dataCy="modal-ok-button" onClick = {modalOkButtonClickHandle}> {okButtonLabel} </RDGButton>}
            </Modal.Footer>
        </Modal>

    )
}

export default RdgModal;