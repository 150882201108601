import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './button-styles.module.scss'
import classNames from 'classnames/bind';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const cx = classNames.bind(styles);

const RDGButton = React.forwardRef<any, {
    isPrimary ?: boolean,
    isSecondary ?: boolean,
    isOutline ?: boolean,
    isLink ?: boolean,
    lg ?: boolean,
    md ?: boolean,
    sm ?: boolean,
    fitContent ?: boolean, // width
    disabled ?: boolean,
    onClick ?: any,
    icon ?: IconProp,
    children ?: any,
    type ?: any,
    name ?:any,
    dataCy ?: string;
}>( (props, ref) => 
     
        <button className={cx('rdg-button', {
            'btn-primary': props.isPrimary,
            'btn-secondary': props.isSecondary,
            'btn-outline': props.isOutline,
            'btn-link': props.isLink,
            'btn-lg': props.lg,
            'btn-md': props.md,
            'btn-sm': props.sm,
            'fit-content': props.fitContent
        })}
        disabled={props.disabled}
        type = {props.type}
        data-cy={props.dataCy}
        ref={ref}
        onClick={props.onClick}>
            {props.icon && <FontAwesomeIcon size="xs" icon={props.icon} />} {props.children}
        </button>
    
);

export default RDGButton;