import getConfig from "next/config";
import http from './httpCore';

const getWorkflowTransitions = (productVersionId, workflowId) => {
  const url = `/products/workflow?productVersionId=${productVersionId}&workflowId=${workflowId}`;
  return http.get(url);
}

const performWorkflowTransition = (productVersionId, workflowId, email, notes, transitionAction) => {
  const basePath = `/products/workflow/transitions?`;
  const paramsString = `productVersionId=${productVersionId}&workflowId=${workflowId}&statusTransitionId=${transitionAction.id}&notes=${notes}`;
  const url = basePath + paramsString;
  
  return http.post(url, transitionAction);
}

const performWorkflowTransitionOnAction = (productVersionId,notes,actionName) => {
  const basePath = `/products/workflow/transitions/${actionName}?`;
  const paramsString = `productVersionId=${productVersionId}&notes=${notes}`;
  const url = basePath + paramsString;
  
  return http.post(url);
}


export { getWorkflowTransitions, performWorkflowTransition, performWorkflowTransitionOnAction }