import React from "react";
import { useState } from "react";
import { Alert} from "react-bootstrap";
import styles from './rdgAlert-styles.module.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const RdgAlert = (props: { alertType?: string; alertHeading?: string; alertBody?: string; }) => {
    const allowedAlertTypes = ["success", "warning", "error"];
    const alertType = allowedAlertTypes.includes(props.alertType) ? props.alertType : "warning";
    const { alertHeading = "Alerting", alertBody} = props;
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const alerTypeToIconMapping = {
        "success" : faCheckCircle,
        "warning" : faInfoCircle,
        "error" : faTimesCircle
    }

    return (
        <Alert show={show} className={styles["alertDisplay-" + alertType]} >
            {/* <FontAwesomeIcon className={styles["iconColor-" + alertType]} icon={alerTypeToIconMapping[alertType]} /> */}
            <span className="p-2">{alertHeading}</span>
            {alertBody}
        </Alert>

    )
}

export default RdgAlert;