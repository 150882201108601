import axios, { AxiosResponse } from 'axios';


const axiosHttp = axios.create({
    baseURL: "/api/v1/rdg",
    headers: {
        'Content-Type':'application/json'
    },
});

axiosHttp.interceptors.response.use( (response: AxiosResponse): Promise<any> => {
    if (!response || response.status != 200) {
        return Promise.reject(response);
    }

    return Promise.resolve(response.data);
}, (onError) => {
    return Promise.reject(onError);
});

export default axiosHttp;
 