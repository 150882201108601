import React, { useEffect, useState } from "react";
import { RDGDropdown } from "@/components/common/dropdown/RDGDropdown";
import {downloadFilesAsZip, downloadMetaData} from '@/lib/api/productAPI';
import ProductTransition  from "@/components/ProductTransition";

const ActionsDropdownButton = (props: {
    product:any
    hasValidationErrors: Boolean,
    workflowTransitions: any,
    disseminationSiteUrl: string,
    setIsProcessing: any,
    setAlertProperties: any
}) => {
    const { product, workflowTransitions, hasValidationErrors, disseminationSiteUrl, setIsProcessing, setAlertProperties } = props;
     // generate eligibleTransitions

    const prepareOptions = () => {

        const transitions = workflowTransitions.filter(trans => !(hasValidationErrors && trans.name == "publish")).map(trans => ({ label: trans.label, value:  trans}));
        let tempOptions =  [ ...transitions,
             {"value":'csv', "label":'Download CSV'},
             {"value":'excel', "label":'Download XLSX'},
         ]
         if(product.status === 'published')
             tempOptions.push({"value":'preview', "label":'Preview'})
         return tempOptions;
         
     }    
    const [options, setOptions] = useState(prepareOptions());

    const [transitionToken, setTransitionToken] = useState({name:"", label:""});
    const [performTransition, setPerformTransition] = useState(false);

    useEffect(()=>{
        setOptions(prepareOptions())
    },[props.workflowTransitions, hasValidationErrors])
                                    
    const handleMetaDataDownload = async(product,filetype) => {
        let res = await downloadMetaData(product.productId,filetype); 
        if(res.ok){
            const objUrl = window.URL.createObjectURL(await res.blob());
            let link = document.createElement('a');
            link.href = objUrl;
            link.setAttribute('download',filetype === 'csv' ? `rdg-${product.productId}.csv`:`rdg-${product.productId}.xlsx`);
            link.click();
            // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                    window.URL.revokeObjectURL(objUrl);
                }, 250);}
        else{
            return 'There was an error downloading files, please try again.';
        }
    }
    const handleProductDownloadFile = async (product) => {
        let res = await downloadFilesAsZip(product.productId); 
        if(res.ok){
            const objUrl = window.URL.createObjectURL(await res.blob());
            let link = document.createElement('a');
            link.href = objUrl;
            link.setAttribute('download',`rdg-${product.productId}.zip`);
            link.click();
            // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                    window.URL.revokeObjectURL(objUrl);
                }, 250);}
        else{
            return 'There was an error downloading files, please try again.';
        }
    }
    const optionsClickHandler = (event) => {        
        if (event.value === "excel" || event.value === "csv"){
            handleMetaDataDownload(product,event.value)
         }
        else if (event.value === "downloadfiles"){
            handleProductDownloadFile(product);
        } 
        else if (event.value === "preview"){
            window.open(disseminationSiteUrl+ '/product/' + product.productId, '_blank' );
        }
        else if(event.label==='Upload files'){          
            window.open('/ingest/'+ product.productId+'#SupportingDocumentation');
        }
        else {
            setTransitionToken(event.value)
            setPerformTransition(true)
        }
    }    
    return (
        <div>
            {performTransition &&
                <ProductTransition 
                    product ={product}
                    transitionToken={transitionToken}
                    setIsProcessing={props.setIsProcessing}
                    setAlertProperties={props.setAlertProperties}
                />
            }
            <RDGDropdown
                id="productTransitions" 
                name={"actions"} 
                options={options}
                menuPortalTarget={document.body}
                isMulti={false}
                placeholder={"Actions"}
                onChange={optionsClickHandler}
                usePrimaryColor={true}
                onBlur={(e) => {}}
                dataCy="actions"
                clearValue={() => {}}
            />
        </div>
    )
}

export default ActionsDropdownButton;