import http from './httpCore';

const schemaApiPath = '/schema';

export const getTermsForVocab = (vocabName) => {
  const url = `${schemaApiPath}/${vocabName}/term`;
  return http.get(url);
}

export const getRedmasFilesForAgency = (agencyCode) => {
  return http.get(`${schemaApiPath}/redmas_files/term?isRedmas=true&agencyCode=${agencyCode}`)
}
