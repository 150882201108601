import React from "react";
import { Spinner, Modal } from "react-bootstrap";
import styles from './spinLoadingIndicator-styles.module.scss';

const SpinLoadingIndicatorModal = (props) => {
    const { show } = props;
    return (
        <Modal dialogClassName={styles.spinLoader} show={show} contentClassName={styles.spinLoaderBackdrop}
            backdrop="static" animation={false} keyboard={false} centered>
            <Modal.Body><Spinner animation="border" /></Modal.Body>
        </Modal>

    )
}

export default SpinLoadingIndicatorModal;