import React, {useState, useEffect, useContext } from "react";
import { UserContext } from "@/lib/context/userContext";
import RdgModal from "@/components/common/modal/RdgModal";
import { performWorkflowTransitionOnAction } from '@/lib/api/statusWorkflowAPI';
import { hasAgencyRoleByCode, hasARole } from '@/lib/api/server-side/radius-api/has-access';
import { roles } from '@/lib/api/server-side/radius-api/constants/roles';
import {useRouter} from 'next/router';


const ProductTransition = (props: {
    product: any,
    transitionToken: {name:string, label:string},
    setIsProcessing: any,
    setAlertProperties: any,
}) => {
    const user = useContext(UserContext);
    const router = useRouter();
    const { product,transitionToken, setIsProcessing, setAlertProperties } = props;
    const [transitionProcessing, setTransitionProcessing] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalProperties, setConfirmModalProperties] = useState({
        showModal: false,
        showHeader: false,
        showHeaderCloseButton: false,
        modalTitle: "",
        modalBody: "",
        showYesButton: true,
        showOkButton: false,
        modalOkButtonClickHandle:  () => { setShowConfirmModal(false) },
        modalYesButtonClickHandle: () => { },
        showNoButton: true,
        modalNoButtonClickHandle: () => { setShowConfirmModal(false) }
    });

    const inValidActionModalProps = {
        modalBody: "Unable to perform action. Please contact support.",
        showModal: true, showYesButton: false, showNoButton: false, showOkButton: true,
    }

    useEffect(()=>{
        if(!transitionProcessing) {
            checkPermissionsAndPerformTransition();
        }
    },[])
    
    const updateProductTransition = () => {
        setIsProcessing(true);
        performWorkflowTransitionOnAction(product.productVersionId,null,transitionToken.name)
            .then(data => {
                const responseStatus = data["status"];
                const finalProductState = responseStatus["name"];
                setIsProcessing(false);
                setAlertProperties({ showAlert: true, alertType: "success", alertHeading: "Successfully " + responseStatus["label"], alertBody: "" });
                if(router.pathname === '/') {
                    if(finalProductState === "unpublished" || finalProductState === 'published') {
                        router.push(`/ingest/${product.productId}#Review`)
                    } else {
                        setTimeout(() => {router.reload()}, 3000)
                    }
                } else {
                    if(finalProductState === "deleted") {
                        setTimeout(() => {router.push('/')}, 3000)
                    } else {
                        setTimeout(() => {router.reload()}, 3000)
                    }
                }
            })
            .catch(err => {
                if(transitionToken.name === 'publish' && router.pathname === '/'){
                    router.push(`/ingest/${product.productId}#Review`)
                }else{
                    router.push('/error/technical');
                }
            })
    }

    const checkPermissionsAndPerformTransition = () => {

        const agencyCode = product.formik['imeta_agencyID'];
        let hasPermission = false;
        let modalText = "";
        setTransitionProcessing(true);
        // if event variable is invalid or if agencyID is missing from form, send error
        if (!agencyCode) {
            setShowConfirmModal(true);
            setConfirmModalProperties({...confirmModalProperties, ...inValidActionModalProps});
            return;
        }
        
        // check if they have permission to do transition within agency
        switch(transitionToken.name) {
            case "delete":
                if(hasAgencyRoleByCode(agencyCode, roles.record_publish, user.rolesAndGroups) || (!agencyCode && hasARole(roles.record_delete, user.rolesAndGroups))) {
                    hasPermission = true;
                    modalText = "By selecting 'Yes', it will no longer be available for future edits. This step cannot be undone."
                }
                break;
            case "publish":
                if(hasAgencyRoleByCode(agencyCode, roles.record_publish, user.rolesAndGroups)) {
                    hasPermission = true;
                    modalText = "By selecting 'Yes', it will be publicly viewable."
                }
                break;
            case "unpublish":
                if(hasAgencyRoleByCode(agencyCode, roles.record_unpublish, user.rolesAndGroups)) {
                    hasPermission = true;
                }
                break;
            case "edit":
                if(hasAgencyRoleByCode(agencyCode, roles.record_update, user.rolesAndGroups)) {
                    hasPermission = true;
                    modalText = "By selecting 'Yes', you will unpublish the project and it will not be publicly viewable until you publish it again."
                }
                break;
            default:
                hasPermission = false;
                modalText = "Action not recognized. Please contact support."


        }
        setShowConfirmModal(true);
        setConfirmModalProperties({
            ...confirmModalProperties,
            modalTitle: hasPermission ? `Are you sure you want to ${transitionToken.label.toLowerCase()} this project?` : 'Error',
            modalBody: hasPermission ? modalText : `You do not have permission to ${transitionToken.label}.` ,
            modalYesButtonClickHandle: hasPermission ? () => {
                setShowConfirmModal(false);
                updateProductTransition();
            } : undefined,
            showYesButton: hasPermission,
            showNoButton: hasPermission,
            showOkButton: !hasPermission,
            showModal: true
        });
    }
    return (
        <>
            {showConfirmModal && <RdgModal {...confirmModalProperties} />}
        </>
    )
}

export default ProductTransition;