import http from './httpCore';
import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();

const productPath = '/products';


export const createProductVersion = (productType, email): Promise<any> => {
  const url = `${productPath}?owner=${email}&productType=${productType}`;
  return http.post(url);
}

const deleteProductVersion = (productVersionId): Promise<any> => {
  const url = `${productPath}/${productVersionId}`;
  return http.delete(url);
}

const createNewVersion = (productVersionId, updateType: 'update' | 'patch'): Promise<any> => {
  const url =  `${productPath}/${productVersionId}/createNewVersion?updateType=${updateType}`;
  return http.post(url);
}

export const getValid = (productVersionId): Promise<any> => {
  const url =  `/products/product/${productVersionId}/validate`;
  return http.post(url);
}

export const updateProductVersion = (product): Promise<any> => {
  const url = `${productPath}/${product.productVersionId}`;
  return http.put(url, product);
}

export const getProductsByProductId = (productId, params: {loadVocab: boolean, getMostRecentOnly: boolean, loadFiles: boolean}) => {
  const url = `${productPath}/productId?productId=${productId}&loadVocab=${params.loadVocab}&getMostRecentOnly=${params.getMostRecentOnly}&loadFiles=${params.loadFiles}`;
  return http.get(url);
}

export const getProductByProductVersionId = (productVersionId, loadVocab): Promise<any> => {
  const url = `${productPath}?productVersionId=${productVersionId}&loadVocab=${loadVocab}`;
  return http.get(url);
}

export const uploadBatchIngestFile = async (file, email, rolesAndGroups): Promise<any> => {

    const url =  `${productPath}/import`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("email", email);

    return http.post(
        url, 
        formData, 
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "rolesAndGroups": JSON.stringify(rolesAndGroups)
            }
        }
    );
}

export async function downloadMetaData(productId,fileType) {
  let apiResponse = await fetch(
    `${publicRuntimeConfig.apiProxyBasePath}/${productPath}/${productId}/metadataFile?fileType=${fileType}`,
  );
  return apiResponse;
}

export async function downloadFilesAsZip(productId) {
  let apiResponse = await fetch(
    `${publicRuntimeConfig.apiProxyBasePath}/${productPath}/downloadFiles?productId=${productId}`,
  );
  return apiResponse;
}